/* eslint react/prop-types: 0 */
import React from 'react'
import { Link } from "gatsby"
import {
    injectIntl
} from 'gatsby-plugin-react-intl'

const Nav = ({ intl, links, baseURL, linkClass }) => (
    <ul className="nav-items">
        {links.map(({ anchor, label, url }, i) => (
            <li key={i}>
                <Link
                    to={`${baseURL[intl.formatMessage({ id: 'lang' })]}${
                        anchor ? '#' : ''
                    }${url && intl.formatMessage({ id: url })}`}
                    alt={intl.formatMessage({ id: label })}
                    className={linkClass}
                >
                    {intl.formatMessage({ id: label })}
                </Link>
            </li>
        ))}
    </ul>
)

export default injectIntl(Nav)
