/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Logo from '../../content/images/logo.svg';
// import {graphql, Link, useStaticQuery} from 'gatsby';
import {Link} from 'gatsby';
import {links, baseURL} from '../helpers/hooks/use-links';
// import {MenuSwitch} from './menu-switch';
// import {SocialLinks} from "./social-links";
// import Language from "./language";
import Nav from "./nav";

// const headerLinksQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         socialNetworks {
//           linkedin
//         }
//       }
//     }
//   }
// `;

export const Header = () => {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        // find current scroll position
    const currentScrollPos = window.pageYOffset;

        // set state based on location info (explained in more detail below)
    setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 400) || currentScrollPos < 250);

        // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
            window.addEventListener('scroll', handleScroll);

            return () => window.removeEventListener('scroll', handleScroll);

    }, [prevScrollPos, visible, handleScroll]);


    const header_class = visible ? `header-visible` : ``;
    // const {site: {siteMetadata: {socialNetworks = {}} = {}} = {}} = useStaticQuery(headerLinksQuery);
  return (
      <>
        <header
          style={{
              top: visible ? '0' : '-60px',
          }}
          className={`header ${header_class}`}
          aria-label="Primary navigation"
        >
          <div className="container">
              <div className="header__inner">
                  <Link to="/" className="header-logo" title="Home">
                    <Logo aria-label="Website logo"/>
                  </Link>
                  <nav className="header__primaryNav">
                      <Nav links={links} baseURL={baseURL} linkClass="header__primaryNav-link"/>
                  </nav>
                  {/*<nav className="header__secondaryNav">*/}
                  {/*    <div className="header__secondaryNav-social">*/}
                  {/*        <SocialLinks socialNetworks={socialNetworks}/>*/}
                  {/*    </div>*/}
                  {/*    <Language/>*/}
                  {/*    <div className="d-block d-lg-none">*/}
                  {/*      <MenuSwitch />*/}
                  {/*    </div>*/}
                  {/*</nav>*/}
              </div>
          </div>
        </header>
        <nav className="mobileMenu">
          <Nav links={links} baseURL={baseURL} linkClass="mobileMenu-link"/>
        </nav>
      </>
  );
};
