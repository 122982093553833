export const links = [
    {
        label: 'nav.services.label',
        url: 'nav.services.url',
        anchor: false
    },
    // {
    //   label: 'nav.portfolio.label',
    //   url: 'nav.portfolio.url',
    //   anchor: true
    // },
    // {
    //     label: 'nav.about.label',
    //     url: 'nav.about.url',
    //     anchor: true
    // },
    // {
    //     label: 'nav.blog.label',
    //     url: 'nav.blog.url',
    //     anchor: true
    // },
    {
        label: 'nav.contact.label',
        url: 'nav.contact.url',
        anchor: true
    }
]


export const baseURL = {
    en: '/',
    nl: '/nl/'
};
