import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {SocialLinks} from './social-links';
import {ContentLinks} from "./content-links";

const footerLinksQuery = graphql`
  query {
    site {
      siteMetadata {
        author
        authorUrl
        socialNetworks {
          linkedin
        }
        copyrightLinks {
          name
          to
          outbound
        }
      }
    }
  }
`;

export const Copyright = () => {
    const {site: {siteMetadata: {authorUrl = '', author = '', socialNetworks = {}, copyrightLinks = {}} = {}} = {}} = useStaticQuery(footerLinksQuery);
    return (
        <footer className="copyrightFooter">
            <div className="container">
                <div className="copyrightFooter__inner">
                    <div className="copyrightFooter__left" data-sal="slide-up" data-sal-delay="300">
                        <ContentLinks links={copyrightLinks}/>
                        Webdevelopment by <a href={authorUrl}>
                         <u>{author}</u>
                    </a> | Brand design by <a href="https://www.studio-omega.net/" target="__blank"><u>Jan van Dongen / Studio Omega</u></a>
                    </div>
                    <div className="copyrightFooter__right" data-sal="slide-up" data-sal-delay="300">
                        <SocialLinks socialNetworks={socialNetworks}/>
                    </div>
                </div>
            </div>
        </footer>
    );
}




