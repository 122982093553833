import React from 'react';

export const Footer = () => {

  return (
    <footer className="footerMain">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6"
               data-sal="slide-up" data-sal-delay="300"
          >
            <h2>EaK Europe</h2>
            <p>
              Meester E.N. Kleffenstraat 6 <br/>
              6842 CV Arnhem <br/>
              Netherlands <br/>
              Phone +31 654 620 665 <br/>
              Chamber of Commerce (KvK) | 81322968 <br/>
            </p>
          </div>
          <div className="col-12 col-sm-6"
               data-sal="slide-up" data-sal-delay="300"
          >
            <h2>About EaK Europe</h2>
            <p>
              We offer customers tailor made services in environmental solutions, business improvement, cost reduction, geographical and portfolio expansion, interim management and last but not least –profitable- sales growth
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
