import React from 'react';
import '../styles/main.scss';
import "fontsource-montserrat/900-normal.css"
import "fontsource-montserrat/700-normal.css"
import "fontsource-montserrat/500-normal.css"
import "fontsource-montserrat/200-normal.css"
import "fontsource-montserrat/400-italic.css"
import "fontsource-raleway/900-normal.css"
import "fontsource-raleway/700-normal.css"
import "fontsource-raleway/400-normal.css"
import "fontsource-raleway/200-normal.css"
import "fontsource-raleway/400-italic.css"
// import { ParallaxProvider } from 'react-scroll-parallax';
import {Header} from './header';
import {Footer} from './footer';
import {Copyright} from './copyright';
import {UpdateBar} from './update-bar';
import PropTypes from 'prop-types';

export const Layout = ({bodyClass, children, simple, fullwidth}) => {

    return (
       <>
          <div className={bodyClass}>
            {!simple && <Header/>}
            {!fullwidth && <div className="container">{children}</div>}
            {fullwidth && children}
            {!simple && <Footer/>}
            {!simple && <Copyright/>}
            <UpdateBar/>
          </div>
      </>
    )
};

Layout.propTypes = {
  simple: PropTypes.bool,
  fullwidth: PropTypes.bool,
  children: PropTypes.node,
  bodyClass: PropTypes.string,
};
