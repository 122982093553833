import {OutboundLink} from 'gatsby-plugin-google-analytics';
import {FaCodepen, FaGithub, FaSpeakerDeck, FaTwitter, FaFacebook, FaInstagram, FaLinkedin, FaYoutube} from 'react-icons/fa';
import React from 'react';
import PropTypes from 'prop-types';

export const SocialLinks = ({socialNetworks}) => (
  <nav className="social-links mr-2">
    {socialNetworks.twitter && <OutboundLink
      className="social-link"
      href={`https://twitter.com/${socialNetworks.twitter}`}
      target="_blank"
      rel="noopener noreferrer"
      title="Twitter">
      <FaTwitter size={24}/>
    </OutboundLink>}
    {socialNetworks.github && <OutboundLink
      className="social-link"
      href={`https://github.com/${socialNetworks.github}`}
      target="_blank"
      rel="noopener noreferrer"
      title="GitHub">
      <FaGithub size={24}/>
    </OutboundLink>}
    {socialNetworks.speakerdeck && <OutboundLink
      className="social-link"
      href={`https://speakerdeck.com/${socialNetworks.speakerdeck}`}
      target="_blank"
      rel="noopener noreferrer"
      title="Speaker Deck">
      <FaSpeakerDeck size={24}/>
    </OutboundLink>}
    {socialNetworks.codepen && <OutboundLink
      className="social-link"
      href={`https://codepen.io/${socialNetworks.codepen}`}
      target="_blank"
      rel="noopener noreferrer"
      title="CodePen">
      <FaCodepen size={24}/>
    </OutboundLink>}
    {socialNetworks.facebook && <OutboundLink
      className="social-link"
      href={`https://facebook.com/${socialNetworks.facebook}`}
      target="_blank"
      rel="noopener noreferrer"
      title="facebook">
      <FaFacebook size={24}/>
    </OutboundLink>}
    {socialNetworks.instagram && <OutboundLink
      className="social-link"
      href={`https://instagram.com/${socialNetworks.instagram}`}
      target="_blank"
      rel="noopener noreferrer"
      title="instagram">
      <FaInstagram size={24}/>
    </OutboundLink>}
    {socialNetworks.linkedin && <OutboundLink
      className="social-link"
      href={`https://www.linkedin.com/in/${socialNetworks.linkedin}`}
      target="_blank"
      rel="noopener noreferrer"
      title="linkedin">
      <FaLinkedin size={24}/>
    </OutboundLink>}
    {socialNetworks.youtube && <OutboundLink
      className="social-link"
      href={`https://youtube.com/${socialNetworks.youtube}`}
      target="_blank"
      rel="noopener noreferrer"
      title="youtube">
      <FaYoutube size={24}/>
    </OutboundLink>}
  </nav>
);

SocialLinks.propTypes = {
  socialNetworks: PropTypes.shape({
    twitter: PropTypes.string,
    github: PropTypes.string,
    speakerdeck: PropTypes.string,
    codepen: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    linkedin: PropTypes.string,
    youtube: PropTypes.string,
  })
};
